import React, { useState } from 'react';
import styled from 'styled-components';
import backgroundImage from '../../../../ImagesVideo/inprocess.jpg';

const ContactContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    background-image: url(${backgroundImage});
    background-size: cover;
    color: #fff;
    padding: 20px;
    font-family: 'Optima', sans-serif;
    
    @media screen and (min-width: 768px) {
        padding: 50px;
        grid-template-columns: 1.5fr 1fr;
    }
`;

const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    
    @media screen and (min-width: 768px) {
        padding: 50px;
    }
`;

const Heading = styled.h2`
    font-family: 'Optima', sans-serif;
    font-weight: 400;
    font-size: 1.8rem;
    color: #fff;
    margin-bottom: 10px;
`;

const Label = styled.label`
    font-family: 'Optima', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 10px;
    color: #fff;
`;

const Input = styled.input`
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
`;

const TextArea = styled.textarea`
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
`;

const Button = styled.button`
    width: 20%;
    margin: 0 auto;
    font-family: 'Optima', sans-serif;
    font-weight: 700;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #555;
    }
`;

const AdditionalText = styled.p`
    font-family: 'Optima', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    color: #fff;
    margin-bottom: 30px;
`;

const MessageContainer = styled.div`
    text-align: center;
    margin-top: 20px;
`;

const GetInTouch = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('message', message);

        try {
            const response = await fetch('https://formspree.io/f/xpzvjyqa', {
                method: 'POST',
                body: formData,
                headers: {
                    Accept: 'application/json',
                },
            });
            if (response.ok) {
                setName('');
                setEmail('');
                setMessage('');
                setSubmitted(true);
            } else {
                console.error('Form submission failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    return (
        <ContactContainer>
            <ContactForm onSubmit={handleSubmit}>
                <Heading>GET IN TOUCH</Heading>
                <AdditionalText>
                    For more information, or to request an appointment, please contact us today.
                </AdditionalText>
                <Label htmlFor="name">Name:</Label>
                <Input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleNameChange}
                    required
                />
                <Label htmlFor="email">Email:</Label>
                <Input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                />
                <Label htmlFor="message">Message:</Label>
                <TextArea
                    id="message"
                    name="message"
                    value={message}
                    onChange={handleMessageChange}
                    rows="4"
                    required
                />
                <Button type="submit">Send</Button>
                {submitted && (
                    <MessageContainer>
                        <p>Your message has been sent.</p>
                    </MessageContainer>
                )}
            </ContactForm>
        </ContactContainer>
    );
};

export default GetInTouch;
