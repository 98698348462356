import React, {useState, useEffect} from 'react'

import Sidebar from '../Home/Components/Sidebar/Sidebar'
import Header from '../Home/Components/Header/Header'
import Hero from './Components/Hero'
import Shape from './Components/Shape'
import Size from './Components/Size'
import Color from './Components/Color'

import ProjectsToSupport from './Components/ProjectsToSupport'
import Photos from './Components/Photos'

import Footer from '../Home/Components/Footer/Footer'



function Products() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>

            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Header toggle={toggle}/>
            <Hero />
            <Shape />
            <Size />
            <Color />
            <ProjectsToSupport />
            <Photos />
            <Footer />

        </>
    )
}

export default Products