import React from 'react'
import Particles from "react-tsparticles"
import styled from 'styled-components';

// import TheProjectsCard from './TheProjectsCard';
// import { TheProjectsData } from './TheProjectsData';

import {Container, ColoredLine, Options} from './TheProjectsElements';

function ProjectsToSupport() {

    const TitleContainer = styled.div`
    text-align: center;
    padding-top: 100px;
    padding-bottom: 50px;
    margin: 0 100px 0 100px;

    @media screen and (max-width:600px){
        margin: 0 50px 0 50px;
    }
    `;

    const Title = styled.h2`
    color: #fff;
    font-family: 'Optima', sans-serif;
    font-size: 1.2rem;
    font-weight: 10;
    line-height: 1.5;

    @media screen and (max-width:600px){
        font-size: 1rem;
    }
    `;

    return (
        <>
            <Container id='projects'>

                <TitleContainer>
                    <Title>Embark on a journey of elegance with our exclusive batches of pearls, ranging from 500 to 10,000 units per harvest. 
                        Elevate your jewelry creations with our premium-quality pearls, meticulously harvested and curated for timeless elegance. 
                        Reach out to us now to bring your designs to life.</Title>
                </TitleContainer>

                <Particles id="particle-0" options={Options} style={{position: 'fixed'}}/>

                {/* <Wrapper>
                    {TheProjectsData.map((data, index) => 
                            <TheProjectsCard data = {data} key={index} />
                    )}
                </Wrapper> */}

            </Container>

            <ColoredLine color='black' />


        </>
    )
}

export default ProjectsToSupport
