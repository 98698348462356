import styled from 'styled-components';


export const Container = styled.div`
    color: #fff;
    height: auto;
    padding-bottom: 40px;
    background-color: #000;
    opacity: 100%
`; 

export const Title = styled.div`
    font-size: 50px;
    font-family: Trebuchet MS, sans-serif;
    justify-content: center;
    text-align: center;
    color: white;
    padding-bottom: 100px;
    padding-top: 200px;

    @media screen and (max-width:600px){
        font-size: 40px;
    }
`

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(14rem, 16rem));
    grid-gap: 100px;
    position: float;
    background-color: #0c0c0c;    
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 20px;

    @media screen and (max-width: 960px) {
        grid-template-columns: repeat(2, minmax(14rem, 16rem));
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1, minmax(14rem, 16rem));
    }
`;

export const CardContainer = styled.div`
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 3px solid #DECFAC;
    z-index: 10000;

    background-color: #fff;
    transition:0.5s;
    
    animation: ease-in-out;
    
    align-items: center;
    justify-content: center;

    
    :hover{
        transform: scale(1.02);
        box-shadow: 0px 0px 15px 0px;
    }
`;

export const ImageWrapper = styled.div`
    align-items: center;
    justify-content: center;
    background-color: transparent;
    min-height: 100%;
    min-width: 100%;
`

export const Image = styled.img`
    overflow: hidden;
    height: 100%;
    width: 100%;
`;

export const CardTextWrapper = styled.div`
    background-color: #101522;
    margin-top: -5px
`

export const CardTitle = styled.div`
    color: #DECFAC;
    font-size:18px;
    justify-content: center;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: 'Optima', sans-serif;
    font-weight: 200;
`;

export const CardDescription = styled.div`
    color: #fff;
    font-size:10px;
    padding-bottom: 1rem;
    text-align: center;
    font-family: 'Optima', sans-serif;
    font-weight: 200;
`;

export const ColoredLine = styled.hr`
    height: 2px;
    width: 100%;
`;

export const Options = {
    "particles": {
      "number": {
        "value": 355,
        "density": {
          "enable": true,
          "value_area": 500
        }
      },
      "color": {
        "value": "#ffffff"
      },
      "shape": {
        "type": "circle",
        "stroke": {
          "width": 0,
          "color": "#000000"
        },
        "polygon": {
          "nb_sides": 5
        },
        "image": {
          "src": "img/github.svg",
          "width": 100,
          "height": 100
        }
      },
      "opacity": {
        "value": 0.48927153781200905,
        "random": false,
        "anim": {
          "enable": true,
          "speed": 0.2,
          "opacity_min": 0,
          "sync": false
        }
      },
      "size": {
        "value": 1,
        "random": true,
        "anim": {
          "enable": true,
          "speed": 2,
          "size_min": 0,
          "sync": false
        }
      },
      "line_linked": {
        "enable": false,
        "distance": 150,
        "color": "#ffffff",
        "opacity": 0.4,
        "width": 1
      },
      "move": {
        "enable": true,
        "speed": 0.2,
        "direction": "none",
        "random": true,
        "straight": false,
        "out_mode": "out",
        "bounce": false,
        "attract": {
          "enable": false,
          "rotateX": 600,
          "rotateY": 1200
        }
      }
    },
    "interactivity": {
      "detect_on": "canvas",
      "events": {
        "onhover": {
          "enable": true,
          "mode": "bubble"
        },
        "onclick": {
          "enable": true,
          "mode": "push"
        },
        "resize": true
      },
      "modes": {
        "grab": {
          "distance": 400,
          "line_linked": {
            "opacity": 1
          }
        },
        "bubble": {
          "distance": 83.91608391608392,
          "size": 1,
          "duration": 3,
          "opacity": 1,
          "speed": 3
        },
        "repulse": {
          "distance": 200,
          "duration": 0.4
        },
        "push": {
          "particles_nb": 4
        },
        "remove": {
          "particles_nb": 2
        }
      }
    },
    "retina_detect": true
  }