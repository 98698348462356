import styled from 'styled-components';
import pearls from '../../../../ImagesVideo/under_water.jpg';

export const Container = styled.div`
    background: #000;
    position: relative;
    background-image: url(${pearls});
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    height: 100vh; 
    @media screen and (max-width: 768px) {
        background-size: auto; 
    }
`;

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        iframe {
            width: 100%;
            max-width: 700px;
        }
    }
`;

