export const PolicyData = [
    {
    id: 0,
    title: "Privacy Policy",
    paragraph: `Last updated: November 25, 2021`
}, 
{
    id: 1,
    paragraph: `This Privacy Policy describes Our policies and procedures on the collection, 
    use and disclosure of Your information when You use the Service and tells You about Your privacy rights 
    and how the law protects You.`
}, 
{
    id: 2,
    paragraph: `We use Your Personal data to provide and improve the Service. By using the Service, You agree to the 
    collection and use of information in accordance with this Privacy Policy. 
    Interpretation and Definitions.`
},
{
    id: 3,
    paragraph: `Interpretation`
},
{
    id: 4,
    paragraph: `The words of which the initial letter is capitalized have meanings defined under the following conditions. 
    The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.`
},
{
    id: 5,
    paragraph: `Definitions`
},
{
    id: 6,
    paragraph: `For the purposes of this Privacy Policy:`
},
{
    id: 7,
    title: `Collecting and Using Your Personal Data`,
    paragraph: `Types of Data Collected`
},
{
    id: 8,
    paragraph: `Personal Data`
},
{
    id: 9,
    paragraph: `While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:`
},
{
    id: 10,
    title: `Usage Data`,
    paragraph: `Usage Data is collected automatically when using the Service.`
},
{
    id: 11,
    paragraph: `Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.`
},
{
    id: 12,
    paragraph: `When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use. Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.`
},
{
    id: 13,
    paragraph: `We may also collect information that Your browser sends whenever you visit our Service or when you access the Service by or through a mobile device.`
},
{
    id: 14,
    title: `Tracking Technologies and Cookies`,
    paragraph: `We use cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:`
},
{
    id: 15,
    paragraph: `Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies: What Are Cookies?`
},
{
    id: 16,
    paragraph: `We use both Session and Persistent Cookies for the purposes set out below:`
},
{
    id: 17,
    paragraph: `Necessary / Essential Cookies`
},
{
    id: 18,
    paragraph: `Purpose: These Cookies are essential to provide you with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide you with those services.`
},
{
    id: 19,
    paragraph: `Cookies Policy / Notice Acceptance Cookies`
},
{
    id: 20,
    paragraph: `Purpose: These Cookies identify if users have accepted the use of cookies on the Website.`
},
{
    id: 21,
    paragraph: `Functionality Cookies`
},
{
    id: 22,
    paragraph: `Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide you with a more personal experience and to avoid You having to re enter your preferences every time You use the Website.`
},
{
    id: 23,
    paragraph: `For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.`
},
{
    id: 24,
    title:`Use of Your Personal Data`,
    paragraph: `Tahiti Noir Elegance may use Personal Data for the following purposes:`
},
{
    id: 25,
    paragraph: `We may share Your personal information in the following situations:`
},
{
    id: 26,
    title:`Retention of Your Personal Data`,
    paragraph: `We will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.`
},
{
    id: 27,
    paragraph: `We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.`
},
{
    id: 28,
    title:`Transfer of Your Personal Data`,
    paragraph: `Your information, including Personal Data, is processed at the Development Team's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to- and maintained on computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.`
},
{
    id: 29,
    paragraph: `Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.`
},
{
    id: 30,
    paragraph: `The Development Team will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.`
},
{
    id: 31,
    title:`Disclosure of Your Personal Data`,
    paragraph: `Law enforcement`
},
{
    id: 32,
    paragraph: `Under certain circumstances, the Development Team may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g, a court or a government agency).`
},
{
    id: 33,
    paragraph: `Other legal requirements`
},
{
    id: 34,
    paragraph: `We may disclose Your Personal Data in the goodfaith belief that such action is necessary to:`
},
{
    id: 35,
    title:`Security of Your Personal Data`,
    paragraph: `The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.`
},
{
    id: 36,
    title:`Links to Other Websites`,
    paragraph: `Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.`
},
{
    id: 37,
    paragraph: `We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.`
},
{
    id: 38,
    title:`Changes to this Privacy Policy`,
    paragraph: `We may update Our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.`
},
{
    id: 39,
    paragraph: `We will let you know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.`
},
{
    id: 40,
    paragraph: `You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.`
},
{
    id: 41,
    title:`Contact Us`,
    paragraph: `If you have any questions about this Privacy Policy, You can contact us:`
},
{
    id: 42,
    paragraph: `By message on Our Home page.`
}

]


