import React from 'react'
import Particles from "react-tsparticles"

import { PolicyData } from './PolicyData';

import {PolicyContainer,
        Textbox,
        TextWrapper,
        Title,
        Paragraph,
        Options} 
        from './PolicyElements'

const bullet1 = [
    { id: 1, name: 'Account means a unique account created for You to access our Service or parts of our Service (not referring to a personal wallet).' },
    { id: 2, name: 'Development Team (referred to as either "Dev Team", "We", "Us" or "Our" in this Agreement) refers to Kameleon`s team of developers.' },
    { id: 3, name: 'Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.' },
    { id: 4, name: 'Country refers to: Canada' },
    { id: 5, name: 'Device means any device that can access the Service such as a computer, a cell phone or a digital tablet.' },
    { id: 6, name: 'Personal Data is any information that relates to an identified or identifiable individual.' },
    { id: 7, name: 'Service refers to the Website.' },
    { id: 8, name: 'Service Provider means any natural or legal person who processes the data on behalf of Kameleon. It refers to individuals employed by Kameleon to facilitate the Service, to provide the Service on behalf of Us, to perform services related to the Service or to assist Kameleon in analyzing how the Service is used.' }
    ];

const bullet2 = [
    { id: 1, name: 'Email address' },
    { id: 2, name: 'First name and last name' },
    { id: 3, name: 'Phone number' },
    { id: 4, name: 'Address, State, Province, ZIP/Postal code, City' }
    ];

const bullet3 = [
    { id: 1, name: 'Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.' },
    { id: 2, name: 'Flash Cookies. Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. ' },
    { id: 3, name: 'Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Development Team, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).' }
    ];

const bullet4 = [
    { id: 1, name: 'Type: Session Cookies' },
    { id: 2, name: 'Administered by: Us' }
    ];

const bullet5 = [
    { id: 1, name: 'Type: Persistent Cookies' },
    { id: 2, name: 'Administered by: Us' }
    ];

const bullet6 = [
    { id: 1, name: 'To provide and maintain our Service, including to monitor the usage of our Service.' },
    { id: 2, name: 'To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give you access to different functionalities of the Service that are available to You as a registered user.' },
    { id: 3, name: 'For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.' },
    { id: 4, name: 'To contact You: To contact You by email or other equivalent forms of electronic communication regarding updates or informative communications related to the functionalities, products or services, including the security updates, when necessary or reasonable for their implementation.' },
    { id: 5, name: 'To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.' },
    { id: 6, name: 'To manage Your requests: To attend and manage Your requests to Us.' },
    { id: 7, name: 'For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.' }
];

const bullet7 = [
    { id: 1, name: 'With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.' },
    { id: 2, name: 'With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.' },
    { id: 3, name: 'With Your consent: We may disclose Your personal information for any other purpose with Your consent.'}
    ];

    const bullet8 = [
        { id: 1, name: 'Comply with a legal obligation' },
        { id: 2, name: 'Protect and defend the rights or property of Kameleon' },
        { id: 3, name: 'Prevent or investigate possible wrongdoing in connection with the Service' },
        { id: 4, name: 'Protect the personal safety of Users of the Service or the public' },
        { id: 4, name: 'Protect against legal liability' }
        ];



function CorePolicy() {
    
    return (
        <>

        <PolicyContainer>

            <Particles id="particle-policy" options={Options} style={{ position: 'fixed'}}/>

            <Textbox id='Privacy Policy'>

                <TextWrapper>

                    <Title> {PolicyData[0]['title']} </Title>

                    <Paragraph style={{ textAlign: 'justify',fontStyle: 'italic' }}> {PolicyData[0]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[1]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[2]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify',textDecorationLine: 'underline'}}> {PolicyData[3]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[4]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify',textDecorationLine: 'underline'}}> {PolicyData[5]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[6]['paragraph']} </Paragraph>
                    
                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet1.map(data => (
                             <li key={data.id}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold' ,textDecorationLine: 'underline'}}> {PolicyData[7]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold' }}> {PolicyData[7]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify',textDecorationLine: 'underline' }}> {PolicyData[8]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[9]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet2.map(data => (
                             <li key={data.id}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' , textDecorationLine: 'underline'}}> {PolicyData[10]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[10]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[11]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[12]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[13]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' , fontWeight: 'bold'}}> {PolicyData[14]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[14]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet3.map(data => (
                             <li key={data.id}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[15]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[16]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' , textDecorationLine: 'underline'}}> {PolicyData[17]['paragraph']} </Paragraph>


                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet4.map(data => (
                             <li key={data.id}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[18]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' , textDecorationLine: 'underline'}}> {PolicyData[19]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet5.map(data => (
                             <li key={data.id}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[20]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' , textDecorationLine: 'underline'}}> {PolicyData[21]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet5.map(data => (
                             <li key={data.id}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[22]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[23]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' , fontWeight: 'bold'}}> {PolicyData[24]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[24]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet6.map(data => (
                             <li key={data.id}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[25]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet7.map(data => (
                             <li key={data.id}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' , fontWeight: 'bold'}}> {PolicyData[26]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[26]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[27]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' , fontWeight: 'bold'}}> {PolicyData[28]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[28]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[29]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[30]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' , fontWeight: 'bold'}}> {PolicyData[31]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify', textDecorationLine: 'underline' }}> {PolicyData[31]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[32]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify', textDecorationLine: 'underline' }}> {PolicyData[33]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[34]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet8.map(data => (
                             <li key={data.id}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' , fontWeight: 'bold'}}> {PolicyData[35]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[35]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold' }}> {PolicyData[36]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[36]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[37]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold' }}> {PolicyData[38]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[38]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[39]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[40]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold' }}> {PolicyData[41]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[41]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {PolicyData[42]['paragraph']} </Paragraph>


                </TextWrapper>
            </Textbox>
        
        </PolicyContainer>
            
        </>
    )
}

export default CorePolicy