import React from 'react'

import {HeroContainer, HeroContent, Font, Font2, HeroBg, VideoBg} from './HeroElements'
import { Link as LinkS} from 'react-scroll' 
import Video from '../../../ImagesVideo/tahiti.mp4';


function Hero() {
      

    return (
        <HeroContainer id='home' style={{height: window.innerHeight}}>

                <HeroBg>
                    <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
                </HeroBg>

                <HeroContent>
                    <Font>
                        Within Tahiti Noir lies an enchanting realm where desire intertwines with luxury.
                    </Font>

                    <Font2>
                        At Tahiti Noir, we pride ourselves on our close partnership with local pearl farmers in Tahiti. 
                        This direct relationship allows us to ensure the highest quality of our Tahitian black pearls, 
                        as well as uphold ethical and sustainable farming practices. By working hand-in-hand with these dedicated farmers, 
                        we not only support their livelihoods but also cultivate a deep appreciation for the artistry and craftsmanship behind each pearl. 
                        Together, we strive to bring the timeless beauty of Tahitian pearls to the world stage, fostering a bond of trust and excellence with our customers.
                    </Font2>
                </HeroContent>

            <LinkS to='home' smooth={true} duration={500} offset={-80}/>
            
        </HeroContainer>
    )
}

export default Hero
