import React from 'react';
import {SidebarContainer, 
        Icon, 
        SidebarWrapper, 
        SidebarMenu,
        SidebarLink} from './SidebarElements';



const Sidebar = ({ isOpen, toggle }) => {
    const handleSidebarClick = (event) => {
        event.stopPropagation(); // Prevent event propagation
    };

    return (
        <SidebarContainer isOpen={isOpen} onClick={handleSidebarClick}>

            <Icon onClick={handleSidebarClick}></Icon>
            
            <SidebarWrapper>      
                 <SidebarMenu>
                     <SidebarLink to="/products" onClick={toggle} exact='true'> OUR PRODUCTS </SidebarLink> 
                 </SidebarMenu>
            </SidebarWrapper>
            
        </SidebarContainer> 
    )
}

export default Sidebar
