import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.nav`
    ${({ scrollHead }) => (scrollHead ? 'animation-name: dissapear;background: #000;': 'animation-name: appear;background: transparent;')}
    animation-duration: 0.5s;
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 20000;
    opacity: 90%;
`;


export const  HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 120;
    width: 100%;
    padding: 0 24px;
    max-width: 1200px;

`;

export const LogoWrapper = styled(LinkR)`
    // font-family: 'Libre Baskerville', serif;
    // font-family: 'Josefin Sans', sans-serif;
    // font-family: 'Lora', serif;
    // font-family: 'Montserrat Alternates', sans-serif;
    // font-family: 'Cormorant Garamond', serif;
    // font-family: 'Playfair Display', serif;
    font-family: 'Optima', sans-serif;
    color: #FFFFFF;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    letter-spacing: 1.4px;
    display: flex;
    align-items: center;
    text-decoration: none;
`;

export const MobileIcon = styled.div`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};      

    @media screen and (max-width: 560px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.5rem;
        cursor: pointer;
        color: #fff;
        &:hover {
            color: #DECFAC;
            transition: 0.2s ease-in-out;
        }
    }
`;

export const HeaderMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 560px) {
        display: none;
    }
`;


export const HeaderItem = styled.li`
    height: 80px;
    display: flex;
    align-items: center;
`;


export const HeaderLink = styled(Link)`
    font-family: 'Optima', sans-serif;
    border-radius: 0px;
    background: transparent;
    justify-content: center;
    white-space: nowrap;
    padding: 1px 20px;
    color: 	#fff;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;

    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: transparent;
        color: beige
    }
`;


