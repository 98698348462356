import React from 'react'

import {FooterContainer, 
        FooterWrap, 
        FooterLinksContainer, 
        FooterLinksWrapper, 
        FooterLinkItems,
        FooterLinkTitle, 
        FooterLink, 
        FooterDescription, 
        SocialLogo, 
        SocialMedia, 
        WebsiteRights,
        SocialMediaWrap, 
        FooterTextWrap, 
        ColoredLine} from './FooterElements'

import Logo from '../Logo/Logo'
import { animateScroll as scroll } from 'react-scroll';


import {FooterText1, FooterText5} from './FooterData'

function Footer() {
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <>
        <FooterContainer>

            <FooterWrap>

                <FooterLinksContainer>

                    <FooterLinksWrapper>

                        <FooterTextWrap>

                            <FooterLinkTitle> TAHITI NOIR ELEGANCE </FooterLinkTitle>
                            
                            <FooterDescription>
                                {FooterText1}
                            </FooterDescription>

                        </FooterTextWrap>


                        <FooterLinkItems>
                            
                            <FooterLinkTitle> RESSOURCES </FooterLinkTitle>

                            <FooterLink to='/products'> Our Products </FooterLink>
                            <FooterLink to={{ pathname: "/termsconditions" }}> Terms of Use </FooterLink>
                            <FooterLink to='/policy'> Privacy Policy </FooterLink>

                            <FooterDescription>
                                {FooterText5}
                            </FooterDescription>
                            
                        </FooterLinkItems>

                    </FooterLinksWrapper>
                </FooterLinksContainer>

                <ColoredLine color='white'/>

                <SocialMedia>
                    <SocialMediaWrap>
                        
                        <SocialLogo to='/' onClick={toggleHome} style={{paddingRight:"10px"}}>
                            <Logo />
                        </SocialLogo>
                        
                        <WebsiteRights>
                        Tahiti Noir Elegance © {new Date().getFullYear()} <br/>All rights reserved.
                        </WebsiteRights>

                    </SocialMediaWrap>

                </SocialMedia>

            </FooterWrap>
        </FooterContainer>
            
        </>
    )
}

export default Footer
