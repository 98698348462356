import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/Home/Home';
import Policy from './pages/Policy/Policy';
import TermsConditions from './pages/TermsConditions/TermsConditions';
import Products from './pages/Products/Products';

function App() {
  return (
    
    <Router>
      <Routes>

        <Route path="/" element={<Home />} exact/>
        <Route path="/policy" element={<Policy />} exact/>
        <Route path="/termsconditions" element={<TermsConditions />} exact/>
        <Route path="/products" element={<Products />} exact/>

      </Routes>
    </Router>
  );
}

export default App;
