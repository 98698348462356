import styled from 'styled-components';

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh; /* Adjusted to full viewport height */
    position: relative;
    z-index: 1;

    :before {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
        z-index: 2;
    }
`;

export const HeroTextBackground = styled.div`
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px 10px;
    border-radius: 3px; 
    font-weight: 10;
`;

export const SmallHeroTextBackground = styled.div`
    background-color: transparent;
    padding: 2rem; /* Adjusted padding for smaller screens */
    border-radius: 3px;
    border: 1px solid #010606;
    font-family: 'Optima', sans-serif;
    font-size: 2rem; /* Adjusted font size for smaller screens */
    font-weight: 10;
    color: #000;
    text-align: center;
    @media screen and (max-width: 968px) {
        font-size: 1.5rem;
        font-weight: 500;
    }
    @media screen and (max-width: 768px) {
        font-size: 1.2rem;
        font-weight: 500;
    }
    @media screen and (max-width: 568px) {
        font-size: 1.5rem;
        font-weight: 500;
    }
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const VideoBg = styled.video`
    width: 100%;
    height:100%;
    --o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`;

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
