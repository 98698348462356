import styled from 'styled-components';

export const LogoContainer = styled.div`
    background: transparent;
`;

export const LogoImage = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
    width: 20rem;
    max-height: 16rem;
    max-width: 16rem;
 

    --o-object-fit: cover;
    object-fit: cover;
    background: transparent;
`;