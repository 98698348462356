import React, {useState} from 'react'

import Sidebar from './Components/Sidebar/Sidebar'
import Header from './Components/Header/Header'
import Hero from './Components/Hero/Hero'
import Video from './Components/Video/Video'
import Project from './Components/Project/Project'
import Contact from './Components/Contact/Contact'
import Footer from './Components/Footer/Footer'



function Home() {

    const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>

            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Header toggle={toggle}/>
            <Hero />
            <Video />
            <Project />
            <Contact />
            <Footer />

        </>
    )
}

export default Home
