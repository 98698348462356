import {React, useState, useEffect} from 'react'
import {FaBars} from 'react-icons/fa'
import {FaTimes} from 'react-icons/fa';
import  Logo  from '../Logo/Logo.js';
import { animateScroll as scroll } from 'react-scroll';


import {
    HeaderContainer, 
    HeaderWrapper, 
    LogoWrapper, 
    MobileIcon, 
    HeaderMenu, 
    HeaderItem, 
    HeaderLink,
} from './HeaderElements';

function Header( { toggle } ) {


    const [scrollHead, setScrollHead] = useState(false);
    const [isMobileIconVisible, setIsMobileIconVisible] = useState(true);

    const changeHead = () => {
      if (window.scrollY >= 80) {
        setScrollHead(true);
      } else {
        setScrollHead(false);
      }
    };
  
    useEffect(() => {
        window.addEventListener('scroll', changeHead);
        return () => {
            window.removeEventListener('scroll', changeHead);
        };
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    const handleMobileIconClick = () => {
        setIsMobileIconVisible(false);
        toggle();
    };

    const handleSidebarToggle = () => {
        setIsMobileIconVisible(true);
        toggle();
    };

    return (
        <>
            <HeaderContainer scrollHead={scrollHead}>
                <HeaderWrapper>

                    <LogoWrapper to ='/' onClick={toggleHome}> 
                       <Logo/> 
                    </LogoWrapper>

                    {isMobileIconVisible ? (
                        <MobileIcon onClick={handleMobileIconClick}>
                            <FaBars />
                        </MobileIcon>
                    ) : (
                        <MobileIcon onClick={handleSidebarToggle}>
                            <FaTimes />
                        </MobileIcon>
                    )}

                    <HeaderMenu>

                        <HeaderItem>
                            <HeaderLink 
                            to="/products"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                            style={{position:'relative', left:'-50px', display:'flex'}}> OUR PRODUCTS </HeaderLink>
                        </HeaderItem>

                    </HeaderMenu>
                     
                </HeaderWrapper>
            </HeaderContainer>
        </>
    )
}

export default Header
