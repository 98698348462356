import React from 'react';
import styled from 'styled-components';

// Define your images
import image1 from '../../../ImagesVideo/Color/Black-Power.png';
import image2 from '../../../ImagesVideo/Color/Dark.webp';
import image3 from '../../../ImagesVideo/Color/Dark-Blue.webp';
import image4 from '../../../ImagesVideo/Color/Grey-Medium.webp';
import image5 from '../../../ImagesVideo/Color/Green-Prosperity.webp';
import image6 from '../../../ImagesVideo/Color/Peacock-Intense.webp';
import image7 from '../../../ImagesVideo/Color/Peacock-Protection.png';
import image8 from '../../../ImagesVideo/Color/Pistachio-Health.webp';
import image9 from '../../../ImagesVideo/Color/Cherry-Love.webp';
import image10 from '../../../ImagesVideo/Color/Ocean-Eternity.webp';


const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 968px) {
    margin: 0 10%;
    text-align: center;
}
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

const Title = styled.h2`
  color: #000;
  font-family: 'Optima', sans-serif;
  font-size: 2.5rem;
  font-weight: 10;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
`;

const ImageWrapper = styled.div`
z-index: 1;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  width: 60%;
  margin: 0 auto;
  transition: transform 0.3s ease; /* Add transition for smooth zoom effect */
  
  &:hover {
    transform: scale(1.5); /* Zoom effect on hover */
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const ImageTitle = styled.h3`
  color: #000;
  font-family: 'Optima', sans-serif;
  font-size: 0.8rem;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 10;

  @media screen and (max-width: 968px) {
    font-size: 0.45rem;
}
`;

const ImageGrid = () => {
  return (
    <Container>
      <TitleContainer>
        <Title>Color of Pearls</Title>
      </TitleContainer>
      <Grid>
        {[
          { src: image1, title: 'Black Power' },
          { src: image2, title: 'Dark' },
          { src: image3, title: 'Dark Blue' },
          { src: image4, title: 'Grey' },
          { src: image5, title: 'Green Prosperity' },
          { src: image6, title: 'Peacock Intense' },
          { src: image7, title: 'Peacock Protection' },
          { src: image8, title: 'Pistachio Health' },
          { src: image9, title: 'Cherry Love' },
          { src: image10, title: 'Ocean Eternity' },
        ].map((image, index) => (
          <ImageWrapper key={index}>
            <Image src={image.src} alt={`Image ${index + 1}`} />
            <ImageTitle>{image.title}</ImageTitle>
          </ImageWrapper>
        ))}
      </Grid>
    </Container>
  );
};

export default ImageGrid;
