import React from 'react'
import {Container, Wrapper} from './VideoPostElements'


function Video() {

    return (

        <Container>
            <Wrapper>

                <div>
                    <iframe 
                        width="700" 
                        height="394" 
                        src={`https://www.youtube.com/embed/4kmGn3rg7X8`} 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                    ></iframe>
                </div>
            
            </Wrapper>
        </Container>

      )
    }
    
    export default Video