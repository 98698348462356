import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
  position: relative;
  background-color: #000;
`;

export const FooterWrap = styled.div`
  padding-top: 24px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
  width: 500px;
  box-sizing: border-box;
  color: #fff;


  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 20px;
  padding-bottom: 16px;
  padding-top: 16px;
  color: #DECFAC;
  font-family: 'Optima', sans-serif;
  font-weight: 200;
`;

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  padding-bottom: 0.5rem;
  font-size: 14px;
  font-weight: 200;

  &:hover {
    color: #DECFAC;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 100%;
  width: 100%;
  align-items: center;
  width: 50%;
  right: 50%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: -40px auto 0 auto;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: -40px;
  font-weight: bold;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 10px;
  padding-right: 10px;
  font-weight: 200;
  font-size: 12px;
  line-height: 1.2;

  @media screen and (max-width: 920px) {
    padding-top: 15%; 
  @media screen and (max-width: 820px) {
    padding-top: 0%; 
`;

export const FooterDescription = styled.div`
display: flex;
max-width: 500px;
font-size: 14px;
padding-bottom: 1rem;
font-weight: 200;

`

export const FooterDescriptionAddress = styled.div`
display: flex;
max-width: 500px;
font-size: 14px;
padding-bottom: 1rem;

@media screen and (max-width: 440px) {
  font-size: 12px;
}

@media screen and (max-width: 380px) {
  font-size: 10px;
}

@media screen and (max-width: 325px) {
  font-size: 9px;
}`

export const FooterTextWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 2%; /* Adjust padding as a percentage of the viewport height */
    padding-bottom: 2%; /* Adjust padding as a percentage of the viewport height */
    width: 100%;
    padding-left: 5%; /* Adjust padding as a percentage of the viewport width */
    padding-right: 5%; /* Adjust padding as a percentage of the viewport width */
    justify-content: center;
    color: #fff;

    @media screen and (max-width: 920px) {
        padding: 5% 10%; /* Adjust padding for smaller screens */
    }
`;

export const ColoredLine = styled.hr`
    color: #fff;
    background-color: #fff;
    height: 2px;
    width: 70%;
`;

