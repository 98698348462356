import React from 'react'

import {HeroContainer, HeroTextBackground, SmallHeroTextBackground,  HeroBg, HeroContent} from './HeroElements'
import { Link as LinkS} from 'react-scroll'

import Image from '../../../../ImagesVideo/pearls.png';

function Hero() {
      

    return (
        <HeroContainer id='home' style={{height: window.innerHeight}}>

            <HeroBg>
                <img src={Image} alt="Pearls" style={{width: '100%', height: '100%', objectFit: 'cover'}} />
            </HeroBg>

            <HeroContent>

                <HeroTextBackground> 
                    <SmallHeroTextBackground>
                         Discover the Essence of Polynesian Luxury
                    </SmallHeroTextBackground>
                </HeroTextBackground>

            </HeroContent>

            <LinkS to='home' smooth={true} duration={500} offset={-80}/>
            
        </HeroContainer>
    )
}

export default Hero
