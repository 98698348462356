import React, { useRef, useState } from 'react';
import Particles from "react-tsparticles"
import styled from 'styled-components';

// Import jewelry images
import jewelry1 from '../../../ImagesVideo/jewelry/jewelry1.png';
import jewelry2 from '../../../ImagesVideo/jewelry/jewelry2.jpg';
import jewelry3 from '../../../ImagesVideo/jewelry/jewelry3.avif';
import jewelry4 from '../../../ImagesVideo/jewelry/jewelry4.avif';
import jewelry5 from '../../../ImagesVideo/jewelry/jewelry5.avif';
import jewelry6 from '../../../ImagesVideo/jewelry/jewelry6.avif';
import jewelry7 from '../../../ImagesVideo/jewelry/jewelry7.jpg';
import jewelry8 from '../../../ImagesVideo/jewelry/jewelry8.jpeg';
import jewelry9 from '../../../ImagesVideo/jewelry/jewelry9.jpeg';
import jewelry10 from '../../../ImagesVideo/jewelry/jewelry10.jpg';

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 500px; /* Adjust container height as needed */
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 50px;
    margin-bottom: -70px;
  }
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

const Title = styled.h2`
  color: #000;
  font-family: 'Optima', sans-serif;
  font-size: 2.5rem;
  font-weight: 10;
  @media (max-width: 768px) {
    font-size: 1.6rem;
    margin 0 15px 0 15px;
  }
`;

const Slider = styled.div`
  display: flex;
  transition: transform 0.5s ease;
`;

const Slide = styled.div`
  position: relative;
  width: 300px; /* Adjust slide width as needed */
  flex: 0 0 auto;
  margin-right: 5%; /* Adjust slide margin as needed */
  margin-left: 4%; /* Adjust slide margin as needed */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const JewelryPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease; /* Zoom effect transition */

  @media (max-width: 768px) {
    margin-left: 30px;
    width: 80%;
    height: 80%;
  }
`;

const Description = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 8px;
  font-family: 'Optima', sans-serif;
  font-size: 0.9rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    margin-left: 30px;
    width: 80%;
    font-size: 0.8rem;
    margin-bottom: 20px;
  }
`;

const ZoomLink = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  &:hover ${JewelryPhoto} {
    transform: scale(1.4); /* Zoom in on hover */
  }
  @media (max-width: 668px) {
    &:hover ${JewelryPhoto} {
        transform: scale(1); /* Zoom in on hover */
      }
  }
`;

const JewelryContainer = () => {
  const sliderRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);

  const jewelryData = [
    { src: jewelry1, alt: 'Jewelry Design 1', description: 'Pendulum Pearl Diamond Necklace' },
    { src: jewelry2, alt: 'Jewelry Design 2', description: 'Studs Earrings with Tahitian Pearl' },
    { src: jewelry3, alt: 'Jewelry Design 3', description: 'Tahitian Pearls with Diamond Studs' },
    { src: jewelry4, alt: 'Jewelry Design 4', description: 'Pandora Ring with Tahitian Pearl' },
    { src: jewelry5, alt: 'Jewelry Design 5', description: 'Muse Ring with Diamond' },
    { src: jewelry6, alt: 'Jewelry Design 6', description: 'Tornade Ring with Tahitian Pearl' },
    { src: jewelry7, alt: 'Jewelry Design 7', description: 'Chanel Haute Joaillerie' },
    { src: jewelry8, alt: 'Jewelry Design 8', description: 'Frederick Missir Necklace' },
    { src: jewelry9, alt: 'Jewelry Design 9', description: 'John Hardy Pearl Necklace' },
    { src: jewelry10, alt: 'Jewelry Design 10', description: 'Yoko London Necklace' },
  ];

  const totalSlides = jewelryData.length;

  const nextSlide = () => {
    setSlideIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  const prevSlide = () => {
    setSlideIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
  };

  const Options = {
    "particles": {
      "number": {
        "value": 355,
        "density": {
          "enable": true,
          "value_area": 500
        }
      },
      "color": {
        "value": "#000000"
      },
      "shape": {
        "type": "circle",
        "stroke": {
          "width": 1,
          "color": "#000000"
        },
        "polygon": {
          "nb_sides": 5
        },
        "image": {
          "src": "img/github.svg",
          "width": 100,
          "height": 100
        }
      },
      "opacity": {
        "value": 0.48927153781200905,
        "random": false,
        "anim": {
          "enable": true,
          "speed": 0.2,
          "opacity_min": 0,
          "sync": false
        }
      },
      "size": {
        "value": 1,
        "random": true,
        "anim": {
          "enable": true,
          "speed": 2,
          "size_min": 0,
          "sync": false
        }
      },
      "line_linked": {
        "enable": false,
        "distance": 150,
        "color": "#ffffff",
        "opacity": 0.4,
        "width": 1
      },
      "move": {
        "enable": true,
        "speed": 0.2,
        "direction": "none",
        "random": true,
        "straight": false,
        "out_mode": "out",
        "bounce": false,
        "attract": {
          "enable": false,
          "rotateX": 600,
          "rotateY": 1200
        }
      }
    },
    "interactivity": {
      "detect_on": "canvas",
      "events": {
        "onhover": {
          "enable": true,
          "mode": "bubble"
        },
        "onclick": {
          "enable": true,
          "mode": "push"
        },
        "resize": true
      },
      "modes": {
        "grab": {
          "distance": 400,
          "line_linked": {
            "opacity": 1
          }
        },
        "bubble": {
          "distance": 83.91608391608392,
          "size": 1,
          "duration": 3,
          "opacity": 1,
          "speed": 3
        },
        "repulse": {
          "distance": 200,
          "duration": 0.4
        },
        "push": {
          "particles_nb": 4
        },
        "remove": {
          "particles_nb": 2
        }
      }
    },
    "retina_detect": true
  }

  return (
    <Container>
      <Particles id="particle-0" options={Options} style={{position: 'fixed'}}/>
      <TitleContainer>
        <Title>Haute Joaillerie Pieces made with Tahitian Pearls</Title>
      </TitleContainer>

      <Slider ref={sliderRef} style={{ transform: `translateX(-${slideIndex * (330)}px)` }}>
        {jewelryData.map((jewelry, index) => (
          <Slide key={index}>
            <ZoomLink>
              <JewelryPhoto src={jewelry.src} alt={jewelry.alt} />
              <Description>{jewelry.description}</Description>
            </ZoomLink>
          </Slide>
        ))}
      </Slider>
      <PrevButton onClick={prevSlide}>←</PrevButton>
      <NextButton onClick={nextSlide}>→</NextButton>
    </Container>
  );
};

const PrevButton = styled.button`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  color: #222;
  font-size: 24px;
  padding: 8px 16px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s ease; /* Hover effect */
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

const NextButton = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  color: #222;
  font-size: 24px;
  padding: 8px 16px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s ease; /* Hover effect */
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

export default JewelryContainer;
