import React from 'react'

import AboutImage from '../../../../ImagesVideo/pearls.webp';
import styled from 'styled-components';


function Project() {

    const Section = styled.section`
        background: transparent;
        padding-top: 50px;
        padding-bottom: 50px;
    `;

    const Container = styled.div`
        max-width: 5xl;
        margin: 0 100px;
        padding: 10px 24px; /* Add horizontal padding */

        @media screen and (max-width: 1268px) {
            margin: 0 0%;
        }
    `;

    const InnerContainer = styled.div`
        max-width: 3xl;
        margin: 0 auto;
    `;

    const Heading = styled.h3`
        font-family: 'Optima', sans-serif;
        font-size: 2.2rem;
        font-weight: 500;
        margin-bottom: 0.75rem;
    `;

    const SubHeading = styled.p`
        font-family: 'Optima', sans-serif;
        font-size: 1.2rem;
        color: #374151;
        font-weight: 600;
        margin-bottom: 2rem;
    `;

    const Content = styled.p`
        font-family: 'Optima', sans-serif;
        font-size: 0.9rem;
        text-align: justify;
        color: #4b5563;
        margin-bottom: 2rem;
        @media screen and (max-width: 1268px) {
            font-size: 0.7rem;
        }
    `;

    const Image = styled.img`
        object-fit: cover;
        width: 40%;
        border-radius: 0.375rem;

        @media screen and (max-width: 1268px) {
            width: 40%;
        }

        @media screen and (max-width: 968px) {
            width: 20%;
        }
    `;

    const Quote = styled.blockquote`
        font-family: 'Optima', sans-serif;
        font-size: 1rem;
        font-style: italic;
        font-weight: 550;
        @media screen and (max-width: 1268px) {
            font-size: 0.8rem;
        }
    `;

    const Author = styled.cite`
        font-family: 'Optima', sans-serif;
        font-size: 0.7rem;
        color: #4b5563;
        font-style: normal;
    `;
      

    return (

        <Container>

            <Section>
                <Container>
                    <InnerContainer>
                        <Heading>Pinctada Margaritifera.</Heading>
                        <SubHeading>Origins of Tahitian Pearl Farming</SubHeading>
                        <Content>
                        The history of pearl farming in Tahiti traces back centuries, deeply rooted in the rich cultural heritage of French Polynesia. 
                        Tahitian pearl cultivation finds its origins in the indigenous practices of the Tuamotu Archipelago, where skilled artisans 
                        meticulously nurtured the Pinctada Margaritifera oyster, known for producing exquisite black pearls. Initially, 
                        pearls were gathered from wild oyster populations, but with the decline of natural stocks due to overharvesting, 
                        efforts turned towards sustainable aquaculture techniques.
                        </Content>
                        <div style={{ display: 'flex' }}>
                            <Image src={AboutImage} alt="About us 02" />
                            <div style={{ marginLeft: '1rem' }}>
                                <SubHeading>Evolution of Modern Pearl Farming</SubHeading>
                                <Content>
                                In the mid-20th century, Tahitian pearl farming underwent a transformative evolution with the introduction of modern 
                                aquaculture methods. Japanese innovators pioneered the development of pearl culturing techniques, revolutionizing the 
                                industry and propelling Tahiti to the forefront of global pearl production. This period saw the establishment of pearl 
                                farms across the azure lagoons of French Polynesia, where farmers delicately nurtured oysters through a meticulous process 
                                of grafting and cultivation. Today, Tahitian pearl farming stands as a beacon of sustainable luxury, blending traditional 
                                craftsmanship with contemporary innovation to produce some of the world's most coveted pearls.
                                </Content>
                                <div>
                                    <Quote>“Each Tahitian pearl tells a story of the ocean's depth, the sky's hue, 
                                            and the Polynesian spirit—a tale of timeless elegance born from the embrace of nature's wonders.“</Quote>
                                    <Author>Robert WAN</Author>
                                </div>
                            </div>
                        </div>
                    </InnerContainer>
                </Container>
                </Section>
  
        </Container>

        )
    }
    
    export default Project